import { Controller } from "@hotwired/stimulus"
import ScrollableElements from "@/javascript/site/scrollable_elements"

export default class extends Controller {
  connect() {
    ScrollableElements.init().then(() => {
      ScrollableElements.registerEvent(this.element, (name) => {
        switch (name) {
          case "down_to_up":
            this.element.style.display = ""
            break
          case "up_to_down":
          case "at_top":
            this.element.style.display = "none"
            break
          default:
        }
      })
    })
  }

  go(_event) {
    window.scrollTo(0, 0)
  }
}
