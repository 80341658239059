.__main_menu-cart
  if lineItems && lineItems.length
    .grid-y(data-referer-from-container="cart~mini_cart")
      .cell.text-center
        a.button(href='/line_items') Посмотреть корзину
      .cell._line-items
        each lineItem in lineItems
          ._line-item(data-controller='layout--main-menu--cart')
            a._link(
              href=`/products/${lineItem.product_id}?cart_warehouse_id=${lineItem.warehouse_id}`,
              data-layout--main-menu--cart-target='lineItem',
              class=(lineItem.is_cart_available ? '' : '_unavailable'),
              )
              ._image
                != pictureImgHtml(productImagePath(lineItem.product_id, lineItem.data_file_name, 'thumb2x'), { alt: '' })
              ._info
                ._product-name= lineItem.product_name
                i= lineItem.variant_name
            ._destroy-wrapper
              a._destroy(
                href="#",
                data-item-id=lineItem.id,
                data-action='layout--main-menu--cart#destroyLineItem',
                data-layout--main-menu--cart-target='destroyLineItemLink') удалить
              a._recover(
                href="#",
                data-item-id=lineItem.id,
                data-action='layout--main-menu--cart#recoverLineItem',
                data-layout--main-menu--cart-target='recoverLineItemLink',
                style="display: none") восстановить
            if !lineItem.is_cart_available
              ._label-unavailable Товар закончился
      if moreProducts
        .cell.text-center
          a(href='/line_items')= moreProducts
  else
    ._empty Пока что в корзине пусто
