import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"
import Lightbox from "@/javascript/lib/lightbox"
import { turboReload } from "@/javascript/lib/vendor/turbo"

export default class extends Controller {
  sign(e) {
    e.preventDefault()

    this.authPopup(this.element.getAttribute("href"), 800, 500)
  }

  authPopup(url, width, height) {
    const left = window.screen.width / 2 - width / 2
    const top = 70
    const authWindow = window.open(
      url,
      "authPopup",
      `menubar=no,toolbar=no,status=no,width=${width}, height=${height}, left=${left}, top=${top}`
    )
    const redirectEvent = (event) => {
      if (event.data.redirect_path) {
        window.removeEventListener("message", redirectEvent)
        authWindow.close()

        if (event.data.page_type === "phone_form") {
          Lightbox.open(event.data.redirect_path, { onClose: turboReload })
        } else {
          Turbo.visit(event.data.redirect_path)
        }
      }
    }

    window.addEventListener("message", redirectEvent)
  }
}
