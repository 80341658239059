import BaseController from "./component_base"
import DesktopTapSuggest from "./desktop_tap_suggest"

const BODY_AUTOCOMPLETE_CLASS_NAME = "search-autocomplete-activated"

export default class extends BaseController {
  connect() {
    super.connect()

    // Prevents blur before button click
    this.submitTarget.addEventListener("mousedown", (e) => e.preventDefault(), { once: true })

    this.tapSuggest = new DesktopTapSuggest({ tapInput: this.tapInputTarget, searchInput: this.searchInputTarget })
  }

  activateBodyAutocomplete() {
    document.body.classList.add(BODY_AUTOCOMPLETE_CLASS_NAME)
  }

  deactivateBodyAutocomplete() {
    document.body.classList.remove(BODY_AUTOCOMPLETE_CLASS_NAME)
    this.suggester.hide()
  }
}
