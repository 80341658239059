import { Controller } from "@hotwired/stimulus"
import { html2dom, productImagePath, cdnImageUrl, staticFileUrl } from "@/javascript/lib/utils"

export default class extends Controller {
  static targets = ["img"]

  connect() {
    this.images = this.element.dataset.images.split(",")

    if (this.images.length <= 1) {
      return
    }

    this.timeoutId = null

    this.element.addEventListener("mouseenter", this.#mouseenter, { passive: true })
  }

  disconnect() {
    this.element.removeEventListener("mouseenter", this.#mouseenter)
  }

  selectSlide(event) {
    const { index } = event.target.dataset
    const thumb2x = productImagePath(this.element.dataset.productId, this.images[index], "thumb2x")
    this.element.querySelector("img").src = staticFileUrl(thumb2x)
    this.element.querySelector("source").srcset = cdnImageUrl(thumb2x)
  }

  #prepareDom(target, images) {
    if (target.querySelector("._panels")) {
      return
    }

    let panels = ""

    images.forEach((img, i) => {
      panels += `
        <div class="_panel" data-index=${i} data-action="mouseenter->products--product-slider#selectSlide">
          <div class="_dot"></div>
        </div>`
    })

    const content = html2dom(`<div class="_panels">${panels}</div>`)

    target.appendChild(content)
  }

  #mouseenter = (event) => {
    this.timeoutId = setTimeout(() => {
      this.#prepareDom(event.target, this.images)
    }, 300)
  }
}
