import { Controller } from "@hotwired/stimulus"
import { noun } from "plural-ru"
import LineItemModel from "@/javascript/shared/models/line_item"
import ResetCartCounterEventBus from "@/javascript/shared/controllers/reset_cart_counter_event_bus"
import { productImagePath, pictureImgHtml, arraySum } from "@/javascript/lib/utils"
import cartTemplate from "../cart/component.pug"

export default class extends Controller {
  static targets = ["userCartProducts", "countCart"]

  connect() {
    this.#clearCache() // сбрасываем кеш при переходе на новую страницу
    this.resetCartListener = ResetCartCounterEventBus.listen(this.resetCart.bind(this))
  }

  disconnect() {
    ResetCartCounterEventBus.detach(this.resetCartListener)
  }

  stopRenderCart() {
    this.#stopRender()
  }

  onClose() {
    this.opened = false
  }

  renderCart() {
    if (this.opened || this.#isCached()) {
      return
    }

    this.doRender = true

    // игнорируем быстрые mouseenter/mouseover
    this.renderTimeoutId = setTimeout(() => {
      if (this.doRender) {
        this.#stopRender()

        const currentRequest = LineItemModel.loadCart()
        this.renderRequest = currentRequest

        currentRequest.then((data) => {
          if (this.renderRequest !== currentRequest) {
            return
          }

          const lineItems = data.cart_li

          this.userCartProductsTarget.innerHTML = this.#renderCartTemplate({
            lineItems,
            countCart: data.count_cart,
          })

          this.resetCart({ detail: data })

          // active не убирается, чтобы при повторном наведении сразу показывалась загруженная ранее корзина
          // велика вероятность, что ее внешний вид не изменился с прошлого показа
          this.element.classList.add("active")

          this.opened = true
          this.renderedAt = new Date().getTime()
        })
      }
    }, 50)
  }

  resetCart(event) {
    this.countCartTarget.textContent = event.detail.count_cart

    if (!event.detail.onlyWidget) {
      // сбрасываем кеш при измененениях в корзине
      // игнорируем удаления/восстановления из попапа, чтобы пользователь имел возможность передумать
      this.#clearCache()
    }
  }

  #renderCartTemplate(data) {
    const bounding = this.element.getBoundingClientRect()
    const itemsCount = Math.floor((window.innerHeight - bounding.top - bounding.height - 60 - 60) / 50)
    const showedLineItems = data.lineItems.slice(0, Math.max(itemsCount, 3))
    const restCountCart = data.countCart - arraySum(showedLineItems, "quantity")

    const templateParams = {
      lineItems: showedLineItems,
      productImagePath,
      pictureImgHtml,
    }

    if (restCountCart > 0) {
      templateParams.moreProducts = `и еще ${restCountCart} ${noun(restCountCart, "товар", "товара", "товаров")}`
    }

    return cartTemplate(templateParams)
  }

  #clearCache() {
    this.renderedAt = null
  }

  #isCached() {
    // 1 минута
    return this.renderedAt && new Date().getTime() - this.renderedAt < 60 * 1000
  }

  #stopRender() {
    this.doRender = false
    clearTimeout(this.renderTimeoutId)
  }
}
