import { Controller } from "@hotwired/stimulus"
import ScrollableElements from "@/javascript/site/scrollable_elements"

export default class extends Controller {
  connect() {
    ScrollableElements.init().then(() => {
      ScrollableElements.registerStickyElement(this.element)
    })
  }
}
