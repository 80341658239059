export default {
  elements: [],
  events: new WeakMap(),

  init() {
    if (!this.promise) {
      this.promise = new Promise((resolve, _reject) => {
        this.lastScrollTop = 0
        this.lastDirection = "down"

        this.ticking = false
        if (this.animationRequestId) {
          cancelAnimationFrame(this.animationRequestId)
        }

        window.removeEventListener("scroll", this.helpers.handleScrolling)
        window.addEventListener("scroll", this.helpers.handleScrolling)

        resolve()
      })
    }

    return this.promise
  },

  registerEvent(element, handler) {
    this.helpers.dropAbsentElements()
    this.elements.push(element)
    this.events.set(element, handler)
  },

  registerStickyElement(stickyEl) {
    this.helpers.initStickyElement(stickyEl)
  },

  get helpers() {
    return {
      initStickyElement: (stickyEl) => {
        const defaultTop = 50

        return this.registerEvent(stickyEl, (name, detail) => {
          let maxTopPosition

          switch (name) {
            case "fast_up":
              maxTopPosition = window.innerHeight - stickyEl.offsetHeight
              stickyEl.style.top = `${defaultTop}px`
              break
            case "up":
              stickyEl.style.top = `${Math.min((parseInt(stickyEl.style.top, 10) || 0) + detail.offset, defaultTop)}px`
              break
            case "down":
              maxTopPosition = Math.min(window.innerHeight - stickyEl.offsetHeight, 5)
              stickyEl.style.top = `${Math.max(
                (parseInt(stickyEl.style.top, 10) || 0) - detail.offset,
                maxTopPosition
              )}px`
              break
            default:
          }
        })
      },

      handleScrolling: () => {
        if (this.ticking) {
          return
        }

        this.animationRequestId = requestAnimationFrame(this.helpers.actionScrolling.bind(this))

        this.ticking = true
      },

      triggerEvent: (name, detail) => {
        this.elements.forEach((element) => {
          if (this.events.has(element)) {
            const handler = this.events.get(element)
            handler(name, detail)
          }
        })
      },

      dropAbsentElements: () => {
        this.elements = this.elements.filter((element) => document.body.contains(element))
      },

      actionScrolling: () => {
        this.ticking = false

        const scrollTop = window.scrollY
        const offset = Math.abs(scrollTop - this.lastScrollTop)

        if (scrollTop === 0) {
          this.helpers.triggerEvent("at_top")
        }

        if (offset < 5) {
          return
        }

        const direction = scrollTop > this.lastScrollTop ? "down" : "up"

        if (this.lastDirection === "up" && direction === "up" && offset > 200) {
          this.helpers.triggerEvent("fast_up")
        } else if (this.lastDirection === "down" && direction === "up") {
          this.helpers.triggerEvent("down_to_up")
        } else if (this.lastDirection === "up" && direction === "down") {
          this.helpers.triggerEvent("up_to_down")
        }

        if (direction === "up") {
          this.helpers.triggerEvent("up", { offset })
        } else if (direction === "down") {
          this.helpers.triggerEvent("down", { offset })
        }

        this.lastDirection = direction
        this.lastScrollTop = scrollTop
      },
    }
  },
}
