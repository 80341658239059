import { makeUrl, fromQueryString } from "@/javascript/lib/utils"
import BaseController from "./component_base"

export default class extends BaseController {
  static values = {
    isAutoNextPage: Boolean,
    pageInitIndx: Number,
  }

  declare isAutoNextPageValue: boolean
  declare pageInitIndxValue: number

  connect() {
    if (this.pageInitIndxValue === 1 && this.isAutoNextPageValue) {
      const observer = new IntersectionObserver(this.#appendOnIntersect, { rootMargin: "1000px" })
      observer.observe(this.advancedContentTarget)
    }
  }

  async paginate() {
    this.ajaxPaginatorTarget.classList.add("loading_box")
    const newProducts = await this.fetchNextPage(makeUrl(this.#path, { ajax: 1 }))

    if (newProducts) {
      this.contentTarget.append(...newProducts)
      this.afterAppend()
    }
  }

  #appendOnIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    if (entries[0].isIntersecting && this.hasAjaxPaginatorTarget) {
      const { page } = fromQueryString(this.#path)

      this.paginate()

      if (Number(page) >= 4) observer.disconnect()
    }
  }

  get #path() {
    return this.ajaxPaginatorTarget.getAttribute("href")
  }
}
