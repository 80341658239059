type DesktopTapSuggestOptions = {
  searchInput: HTMLInputElement
  tapInput: HTMLInputElement
}

export default class {
  #searchInput: HTMLInputElement
  #tapInput: HTMLInputElement
  #onInput: () => void
  #onKeydown: (event: KeyboardEvent) => void

  constructor(options: DesktopTapSuggestOptions) {
    this.#searchInput = options.searchInput
    this.#tapInput = options.tapInput

    this.#onInput = this.#clear.bind(this)
    this.#searchInput.addEventListener("input", this.#onInput)

    this.#onKeydown = this.#fillByTab.bind(this)
    this.#searchInput.addEventListener("keydown", this.#onKeydown)
  }

  update(taps: AutocompleteTap[]) {
    if (!this.#searchInput.value) return

    const tap = taps.find(({ relatedSearch }) => relatedSearch.startsWith(this.#searchInput.value))
    if (tap) this.#tapInput.value = tap.relatedSearch
  }

  destroy() {
    this.#searchInput.removeEventListener("input", this.#onInput)
    this.#searchInput.removeEventListener("keydown", this.#onKeydown)
  }

  #clear() {
    this.#tapInput.value = ""
  }

  #fillByTab(event: KeyboardEvent) {
    if (event.key !== "Tab") return

    event.preventDefault()

    if (!this.#tapInput.value) return

    this.#searchInput.value = `${this.#tapInput.value} `
    this.#searchInput.dispatchEvent(new Event("input"))
  }
}
