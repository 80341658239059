import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["category", "menu"]

  delay = 50 // ms delay when user appears to be entering submenu
  mouseLocsTracked = 3 // number of past mouse locations to track

  connect() {
    this.mouseLocs = []
    this.timeoutId = null
    this.activeRow = null

    this.#setHover(this.categoryTargets[0])
    this.#setEvents()
  }

  #setEvents() {
    this.menuTarget.addEventListener("mouseleave", this.#clearTimeout)
    this.categoryTargets.forEach((el) => {
      el.addEventListener("mousemove", this.#mousemoveRow)
    })
  }

  #setHover(target) {
    this.categoryTargets.forEach((el) => {
      if (target !== el) {
        el.classList.remove("_hover")
      }
    })
    target.classList.add("_hover")
  }

  #clearTimeout = (_e) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  #mousemoveRow = (e) => {
    this.#clearTimeout()
    this.mouseLocs.push({ x: e.pageX, y: e.pageY })

    if (this.mouseLocs.length > this.mouseLocsTracked) {
      this.mouseLocs.shift()
    }

    this.#possiblyActivate(e.target)
  }

  #activate = (target) => {
    if (target === this.activeRow) {
      return
    }
    this.#setHover(target)
    this.activeRow = target
  }

  #possiblyActivate = (target) => {
    const delay = this.#activationDelay()

    if (delay) {
      this.timeoutId = setTimeout(() => {
        this.#possiblyActivate(target)
      }, delay)
    } else {
      this.#activate(target)
    }
  }

  #activationDelay = () => {
    if (!this.activeRow) {
      return 0
    }

    const loc = this.mouseLocs[this.mouseLocs.length - 1]
    let prevLoc = this.mouseLocs[0]

    if (!loc) {
      return 0
    }

    if (!prevLoc) {
      prevLoc = loc
    }

    if (prevLoc.x < loc.x) {
      return this.delay
    }

    return 0
  }
}
