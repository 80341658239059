import { Controller } from "@hotwired/stimulus"
import { request } from "@/javascript/shared/common/request"

export default class extends Controller {
  static targets = ["list"]
  static values = { url: String }

  connect() {
    this.#clearCache() // сбрасываем кеш при переходе на новую страницу
  }

  render() {
    if (this.opened || this.#isCached()) {
      return
    }

    this.doRender = true

    // игнорируем быстрые mouseenter/mouseover
    this.renderTimeoutId = setTimeout(() => {
      if (this.doRender) {
        this.#stopRender()

        const currentRequest = this.loadPromocodes()
        this.renderRequest = currentRequest

        currentRequest.then((html) => {
          if (this.renderRequest !== currentRequest) {
            return
          }

          this.listTarget.innerHTML = html

          this.opened = true
          this.renderedAt = new Date().getTime()
        })
      }
    }, 50)
  }

  stopRender() {
    this.#stopRender()
  }

  onClose() {
    this.opened = false
  }

  async loadPromocodes() {
    const { html } = await request.get(this.urlValue)
    return html
  }

  #isCached() {
    // 1 минута
    return this.renderedAt && new Date().getTime() - this.renderedAt < 60 * 1000
  }

  #clearCache() {
    this.renderedAt = null
  }

  #stopRender() {
    this.doRender = false
    clearTimeout(this.renderTimeoutId)
  }
}
