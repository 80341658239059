import { Controller } from "@hotwired/stimulus"
import LineItemModel from "@/javascript/shared/models/line_item"
import ResetCartCounterEventBus from "@/javascript/shared/controllers/reset_cart_counter_event_bus"

export default class extends Controller {
  static targets = ["destroyLineItemLink", "recoverLineItemLink", "lineItem"]

  destroyLineItem(e) {
    e.preventDefault()

    LineItemModel.destroy(this.destroyLineItemLinkTarget.dataset.itemId).then((data) => {
      this.destroyLineItemLinkTarget.style.display = "none"
      this.recoverLineItemLinkTarget.style.display = ""
      this.lineItemTarget.classList.add("_destroyed")

      ResetCartCounterEventBus.trigger({ onlyWidget: true, ...data })
    })
  }

  recoverLineItem(e) {
    e.preventDefault()

    LineItemModel.recover(this.recoverLineItemLinkTarget.dataset.itemId).then((data) => {
      this.recoverLineItemLinkTarget.style.display = "none"
      this.destroyLineItemLinkTarget.style.display = ""
      this.lineItemTarget.classList.remove("_destroyed")

      ResetCartCounterEventBus.trigger({ onlyWidget: true, ...data })
    })
  }
}
