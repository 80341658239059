import { Controller } from "@hotwired/stimulus"
import ScrollableElements from "@/javascript/site/scrollable_elements"
import { request } from "@/javascript/shared/common/request"

export default class extends Controller {
  static values = { catalogUrl: String }
  static targets = ["tab", "catalogList"]

  connect() {
    ScrollableElements.init().then(() => {
      ScrollableElements.registerEvent(this.element, (name) => {
        switch (name) {
          case "down_to_up":
            this.element.classList.add("_menu-pinned")
            break
          case "up_to_down":
          case "at_top":
            this.element.classList.remove("_menu-pinned")
            break
          default:
        }
      })
    })

    if (!this.catalogListTarget.childNodes.length) {
      this.tabTarget.addEventListener("mouseenter", this.#loadCatalog)
    }
  }

  #loadCatalog = async () => {
    const { html } = await request.get(this.catalogUrlValue)
    this.catalogListTarget.innerHTML = html
    this.catalogListTarget.hidden = false
    this.tabTarget.removeEventListener("mouseenter", this.#loadCatalog)
  }
}
